<template>
  <!-- https://github.com/GriffinJohnston/ldrs/blob/303c3124678ac0e29b8960e2d209995494c9c343/src/elements/bouncy.ts -->
  <div class="loading-container">
    <div class="cube">
      <div class="cube__inner" />
    </div>
    <div class="cube">
      <div class="cube__inner" />
    </div>
    <div class="cube">
      <div class="cube__inner" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoadingAnimation'
}
</script>

<style lang="scss" scoped>
@import '../assets/colors';

$uib-size: 45px;
$uib-color: $white;
$uib-speed: 1.75s;

.loading-container {
  align-items: flex-end;
  display: flex;
  height: calc($uib-size * 0.6);
  justify-content: space-between;
  margin: 10px auto;
  padding-bottom: 20%;
  width: $uib-size;
}

.cube {
  animation: jump $uib-speed ease-in-out infinite;
  flex-shrink: 0;
  height: calc($uib-size * 0.2);
  width: calc($uib-size * 0.2);
}

.cube__inner {
  animation: morph $uib-speed ease-in-out infinite;
  background-color: $uib-color;
  border-radius: 25%;
  display: block;
  height: 100%;
  transform-origin: center bottom;
  transition: background-color 0.3s ease;
  width: 100%;
}

.cube:nth-child(2) {
  animation-delay: calc($uib-speed * -0.36);

  .cube__inner {
    animation-delay: calc($uib-speed * -0.36);
  }
}
.cube:nth-child(3) {
  animation-delay: calc($uib-speed * -0.2);

  .cube__inner {
    animation-delay: calc($uib-speed * -0.2);
  }
}

@keyframes jump {
  0% {
    transform: translateY(0px);
  }

  28% {
    transform: translateY(0px);
    animation-timing-function: ease-out;
  }

  50% {
    animation-timing-function: ease-in;
    transform: translateY(-200%);
  }

  74% {
    animation-timing-function: ease-in;
    transform: translateY(0px);
  }
}

@keyframes morph {
  0% {
    transform: scaleY(1);
  }

  10% {
    transform: scaleY(1);
  }

  20%,
  25% {
    animation-timing-function: ease-in-out;
    transform: scaleY(0.6) scaleX(1.3);
  }

  30% {
    animation-timing-function: ease-in-out;
    transform: scaleY(1.15) scaleX(0.9);
  }

  40% {
    transform: scaleY(1);
  }

  72%,
  87%,
  100% {
    transform: scaleY(1);
  }

  77% {
    transform: scaleY(0.8) scaleX(1.2);
  }
}
</style>
